@media screen and (min-width: 601px) {
  #upload-img {
    object-fit: contain;
    max-width: 80%;
  }
  label {
    cursor: pointer;
  }
  .btn-upload {
    border-radius: 50px;
    color: #11ab7c;
    border: none;
  }
  .btn-upload:hover {
    color: black;
  }
  .photograph {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 60px 0;
    cursor: pointer;
  }
  .thumbnail-head {
    font-size: 40px;
    padding-top: 10px;
    margin-bottom: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
  }
  .text-container {
    max-width: 1000px;
  }
  .upload-comment::placeholder {
    font-size: 20px;
    padding-top: 12px;
    padding-left: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #39393982;
  }
  .btn-upload-cancel {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .upload-typeahead {
    width: 50%;
  }
  .upload-textarea {
    width: 50%;
  }
  .upload-textarea-div{
   width: 100%; 
  }
}
@media screen and (max-width: 600px) {
  #upload-img {
    object-fit: contain;
    max-width: 80%;
  }
  label {
    cursor: pointer;
  }
  .btn-upload {
    border-radius: 50px;
    color: #11ab7c;
    border: none;
  }
  .btn-upload:hover {
    color: black;
  }
  .photograph {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 60px 0;
    cursor: pointer;
  }
  .thumbnail-head {
    font-size: 40px;
    padding-top: 10px;
    margin-bottom: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
  }
  .text-container {
    max-width: 1000px;
  }
  .upload-comment::placeholder {
    font-size: 20px;
    padding-top: 12px;
    padding-left: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #39393982;
  }
  .btn-upload-cancel {
    font-size: 2w-50 w-500px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .upload-textarea {
    width: 100%;
  }
}
