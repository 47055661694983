@media screen and (min-width: 601px) {
  .log-body {
    background-color: #f4f4f4;
  }
  .log-header {
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    background-color: #ffffff;
  }
  .log-header-text {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .log {
    border-radius: 3px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
    margin-bottom: 16px;
  }
  .log-modal {
    border-radius: 5px;
  }
  .log-overflow {
    overflow-y: scroll;
  }
  .log-points {
    background-color: #d1e8b8;
  }
  .post-log-thumbnail {
    width: 48px;
    height: 48px;
    border-radius: 3px;
    object-fit: cover;
    margin-right: 16px;
  }
  .log-nume {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .log-point-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #9a9a9a;
  }
  .profile-direct {
    cursor: pointer;
  }
  .msg-time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9d9d9d;
  }
  .msg-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 2px;
  }
  .msg-user {
    cursor: pointer;
  }
  .log-pipe {
    font-size: 52px;
  }
  .close-button {
    position: absolute;
    right: -40px;
    top: -10px;
  }
}

@media screen and (max-width: 600px) {
  .modal{
    padding-left: 0 !important;
  }
  .log-body {
    background-color: #f4f4f4;
  }
  .log-header {
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    background-color: #ffffff;
  }
  .log-header-text {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .log {
    border-radius: 3px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
    margin-bottom: 16px;
  }
  .log-modal {
    border-radius: 5px;
  }
  .log-overflow {
    overflow-y: scroll;
  }
  .log-points {
    background-color: #d1e8b8;
  }
  .post-log-thumbnail {
    width: 48px;
    height: 48px;
    border-radius: 3px;
    object-fit: cover;
    margin-right: 16px;
  }
  .log-nume {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .log-point-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #9a9a9a;
  }
  .profile-direct {
    cursor: pointer;
  }
  .msg-time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9d9d9d;
  }
  .msg-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 2px;
  }
  .msg-user {
    cursor: pointer;
  }
  .log-pipe {
    font-size: 52px;
  }
}
