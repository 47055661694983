@media screen and (min-width: 601px) {
  .profile-nav.active {
    border-bottom: 2px solid blue;
  }
  .profile-navbar-contents {
    border-radius: 5px;
  }
  .padding-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .btn-tool {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9a9a9a;
    padding: 0;
  }
  .btn-tool.active {
    border-bottom: solid 2px #00d445;
    border-radius: 0;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .profile-user-name {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .profile-user-fullname {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00000046;
  }
  .profile.container {
    max-width: 1500px;
  }
  .btn-points {
    border-radius: 3px;
    background-image: linear-gradient(106deg, #00d445, #6df499 114%);
  }
  .point-num {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .point-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .post-count {
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .post-count-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .photo-count {
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .photo-count-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .btn-disabled {
    pointer-events: none;
  }
  .profile-posts {
    width: "1150px";
  }
  .profile-navbar {
    position: sticky;
    top: 73px;
    z-index: 3;
    background-color: #f4f4f4;
    padding: 30px 0px 0px 0px;
  }
}

@media screen and (max-width: 600px) {
  .profile-posts {
    width: auto;
  }
  .profile-navbar-contents {
    border-radius: 5px;
  }
  .profile-navbar {
    position: sticky;
    top: 35px;
    z-index: 3;
    background-color: #f4f4f4;
    padding: 30px 0px 0px 0px;
  }
  .profile-nav.active {
    border-bottom: 2px solid blue;
  }
  .padding-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .btn-tool {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9a9a9a;
    padding: 0;
  }
  .btn-tool.active {
    border-bottom: solid 2px #00d445;
    border-radius: 0;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .profile-user-name {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .profile-user-fullname {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00000046;
  }
  .profile.container {
    max-width: 1500px;
  }
  .btn-points {
    border-radius: 3px;
    background-image: linear-gradient(106deg, #00d445, #6df499 114%);
  }
  .point-num {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .point-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .post-count {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .post-count-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .photo-count {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .photo-count-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .btn-disabled {
    pointer-events: none;
  }
}
