@media screen and (min-width: 601px) {
  .people::-webkit-scrollbar {
    display: none;
  }
  .top-img {
    width: 72px;
    height: 72px;
    border-radius: 45px;
    object-fit: cover;
    margin-bottom: 6px;
  }
  .person {
    margin-top: 50px;
    margin-bottom: 53px;
    margin-left: 47px;
    margin-right: 47px;
  }
  .headline {
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .sub-head {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7c7c7c;
  }
  .person-name {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .person-point {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
}

@media screen and (max-width: 600px) {
  .people::-webkit-scrollbar {
    display: none;
  }
  .top-img {
    width: 72px;
    height: 72px;
    border-radius: 45px;
    object-fit: cover;
    margin-bottom: 6px;
  }
  .person {
    margin-top: 50px;
    margin-bottom: 53px;
    margin-left: 0px;
    margin-right: 25px;
  }
  .headline {
    font-size: 22px;
    text-align: left;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .sub-head {
    font-size: 12px;
    text-align: left;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
    color: #7c7c7c;
  }
  .person-name {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .person-point {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
}
