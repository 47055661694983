.viewPost {
  position: relative;
}
button:focus {
  outline: 0;
}
.article {
  overflow-y: scroll;
  height: auto;
}
.article::-webkit-scrollbar {
  display: none;
}
/* .asideContent {
  height: 40vh;
  position: sticky;
  top: 120px;
  margin-left: 115px;
  margin-right: 70px;
} */
.input-group-prepend {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.comment {
  position: relative;
}
.comments {
  min-height: 10rem;
}
/* .comments::placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  min-width: 10rem;
} */
.comments::-webkit-scrollbar {
  display: none;
}
.post-comment-button {
  position: absolute;
  z-index: 2;
  color: #ffffff;
  right: 15px;
  bottom: 15px;
}

.formControl {
  display: block;
  width: 100%;
  padding: 0.6rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  background-clip: padding-box;
  border: solid 1px #e3e3e3;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}
.btn-icon {
  background-color: transparent;
  border-radius: 3px;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 50px;
  font-size: 12px;
  max-height: 50px;
}
.btn-icon:hover {
  background-color: #e3e3e3;
}
.btn-icon.active {
  transition: 1s ease-in-out;
  border-color: #00d445;
}
.btn-icon:disabled {
  color: #0000001c !important;
}
.btn-comment:hover {
  color: green;
}
.active_vote {
  color: green;
}
.btn-social {
  height: 20px;
  width: 20px;
}
.post-user {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.post-time {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6a6a;
}

.story {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.post-tag {
  border: black 1px solid;
  font-size: 18px;
  margin-bottom: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.article-tumbnail {
  height: 500px;
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
}
@media screen and (max-width: 1200px) and (min-width: 1100px) {
  .asideContent {
    margin-left: 5% !important;
  }
}
@media screen and (max-width: 1100px) and (min-width: 1000px) {
  .asideContent {
    margin-left: 1% !important;
  }
}
@media screen and (max-width: 1000px) and (min-width: 992px) {
  .asideContent {
    position: sticky;
    padding: 15px 25px 15px 25px;
    top: 56px;
    z-index: 1;
    background-color: #ffffff;
  }
}
@media screen and (min-width: 991px) {
  .asideContent {
    position: sticky;
    top: 200px;
    margin-bottom: 15px;
    margin-left: 10%;
  }
  .article-container {
    max-width: 900px !important;
  }
  .main-content.container {
    max-width: 1140px;
  }
  .post-view-user-thumbnail {
    height: 32px;
    width: 32px;
    border-radius: 20px;
    object-fit: cover;
  }
  .post-user {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .post-time {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .article-layout {
    position: relative;
  }
}
@media screen and (max-width: 990px) {
  .article {
    padding-left: 7px;
    padding-right: 8px;
  }
  .article-container {
    margin-top: 200px;
    padding: 0;
  }
  .aside-content-tools {
    width: 100%;
  }
  .main-content.container {
    margin-left: 53px;
    max-width: 1140px;
  }
  .article-tumbnail {
    height: 500px;
    width: 100%;
    object-fit: cover;
    border-radius: 2px;
    padding: 0px 8px 0px 8px;
  }
  .main-content.container {
    margin-left: 0px;
  }
  .asideContent {
    position: sticky;
    padding: 15px 25px 15px 25px;
    top: 56px;
    z-index: 1;
    background-color: #ffffff;
  }
  .post-view-user-thumbnail {
    height: 40px;
    width: 40px;
    border-radius: 25px;
    object-fit: cover;
    margin-right: 12px;
  }
  .post-user {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .post-time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .article-layout {
    position: relative;
  }
}

@media screen and (max-width: 600px) {
  .article {
    padding-left: 7px;
    padding-right: 8px;
  }
  .article-tumbnail {
    height: 500px;
    width: 100%;
    object-fit: cover;
    border-radius: 2px;
    padding: 0px 8px 0px 8px;
  }
  .main-content.container {
    margin-left: 0px;
  }
  .asideContent {
    position: sticky;
    padding: 10px 24px 10px 24px;
    top: 56px;
    z-index: 1;
    background-color: #ffffff;
  }
  .post-view-user-thumbnail {
    height: 40px;
    width: 40px;
    border-radius: 25px;
    object-fit: cover;
    margin-right: 12px;
  }
  .post-user {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .post-time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .article-layout {
    position: relative;
  }
}
