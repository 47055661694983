@media screen and (min-width: 991px) {
  .drop-content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 50vh;
  }
  .drop-content::-webkit-scrollbar {
    display: none;
  }
  .notification-user-thumbnail {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    object-fit: cover;
  }
  .notify-user-name {
    padding-right: 8px;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .notify-time {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .notify-title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 6px;
  }
  .notify-content {
    margin-left: 6px;
    cursor: pointer;
  }
  .notify-type {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .btn-notify {
    background-color: #f4f4f4;
  }
  .notification-content {
    padding-top: 20px;
    padding-bottom: 16px;
    background-color: #ffffff;
  }
  .notification-content:hover {
    background-color: #f4f4f4;
  }
  .notify-section1 {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .notify-page {
    min-height: 100vh;
  }
}

@media screen and (max-width: 990px) {
  .drop-content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 50vh;
  }
  .drop-content::-webkit-scrollbar {
    display: none;
  }
  .notification-user-thumbnail {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    object-fit: cover;
  }
  .notify-user-name {
    padding-right: 8px;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .notify-time {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .notify-title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 6px;
  }
  .notify-content {
    margin-left: 6px;
    cursor: pointer;
  }
  .notify-type {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .btn-notify {
    background-color: #f4f4f4;
  }
  .notification-content {
    padding-top: 20px;
    padding-bottom: 16px;
    background-color: #ffffff;
  }
  .notification-content:hover {
    background-color: #f4f4f4;
  }
  .notify-section1 {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .notify-page {
    min-height: 100vh;
  }
}
@media screen and (max-width: 600px) {
  .drop-content {
    height: 80vh;
  }
}
