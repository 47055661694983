.editorStyle {
  min-height: 18rem;
}
.rdw-editor-main {
  min-height: auto;
}
.create {
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.title {
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393939;
}
.description {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393939;
}
.input:focus,
.form-control:focus {
  color: none;
  background-color: none;
  border-color: none;
  outline: 0;
  box-shadow: none;
}
.form-style {
  background-color: white;
  padding: 60px 100px;
}
@media screen and (min-width: 601px) {
  .editorStyle {
    min-height: 18rem;
  }
  .rdw-editor-main {
    min-height: auto;
  }
  .create {
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .title {
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #393939;
  }
  .description {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #393939;
  }
  .input:focus,
  .form-control:focus {
    color: none;
    background-color: none;
    border-color: none;
    outline: 0;
    box-shadow: none;
  }
  .form-style {
    background-color: white;
    padding: 60px 100px;
  }
}

@media screen and (max-width: 600px) {
  .editorStyle {
    min-height: 18rem;
  }
  .rdw-editor-main {
    min-height: auto;
  }
  .create {
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .title {
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #393939;
  }
  .description {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #393939;
  }
  .input:focus,
  .form-control:focus {
    color: none;
    background-color: none;
    border-color: none;
    outline: 0;
    box-shadow: none;
  }
  .form-style {
    background-color: white;
    padding: 20px 30px;
  }
}