/* posts */
.post-create-thumbnail {
  height: 48px;
  width: 48px;
  border-radius: 30px;
  object-fit: cover;
  right: 0;
  top: 25px;
  left: 20px;
}
.post-create-button {
  position: absolute;
  z-index: 2;
  right: 25px;
  bottom: 25px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.post-comment-thumbnail {
  height: 32px;
  width: 32px;
  border-radius: 30px;
  object-fit: cover;
}
.post-comment-user {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.post-comment-time {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6a6a;
}
.post-comment-comment {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #6a6a6a;
}
.post-comment-vote {
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.post-comment-box {
  padding: 30px 138px 0px 85px !important;
  border-radius: 3px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
}
.post-comment-box::placeholder {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727272;
}
@media screen and (max-width: 600px) {
  .post-comment-box::placeholder {
    font-size: 14px;
    text-align: start;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #727272;
  }
  .post-comment-box {
    padding: 30px 5px 4px 77px !important;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.08) 1px 1px 4px 0px;
    border: 1px solid rgb(233, 233, 233);
    background-color: rgb(255, 255, 255);
    height: 128px;
  }
}
/* photos */
.photo-create-thumbnail {
  height: 24px;
  width: 24px;
  border-radius: 15px;
  object-fit: cover;
  right: 0;
  top: 10px;
  left: 10px;
}
.photo-create-button {
  position: absolute;
  z-index: 2;
  right: 0px;
  bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.photo-comment-thumbnail {
  height: 24px;
  width: 24px;
  border-radius: 15px;
  object-fit: cover;
}
.photo-comment-user {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.photo-comment-time {
  opacity: 0.3;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}
.photo-comment-comment {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5f5f;
}

.photo-comment-vote {
  width: 8px;
  height: 10px;
}
.photo-comment-vote-count {
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.photo-comment-box::placeholder {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5f5f;
}
.photo-comment-box.formControl {
  font-size: 10px;
  font-weight: normal;
  line-height: normal;
}
.photo-comment-box {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #5f5f5f;
}

/* common */
.formControl:focus {
  outline: 0;
}
.comment-border {
  border-radius: 3px;
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
}
.btn-comment-more {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00d445;
  border-radius: 3px;
  border: solid 1px #00d445;
  background-color: #ffffff;
}

/* comment create loader */
.text-loader {
  animation-duration: 2s;
  animation-name: fadetextout;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes fadetextout {
  from {
    color: #000000;
  }
  to {
    color: #00000003;
  }
}

.img-loader {
  animation-duration: 2s;
  animation-name: fadeimg;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes fadeimg {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
