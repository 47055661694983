.related::-webkit-scrollbar {
  display: none;
}
.related {
  overflow-x: scroll;
}
.related-img {
  height: 300px;
  object-fit: cover;
}
.related-heading {
  font-size: 40px;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding-bottom: 24px;
}
.related-contents {
  background-color: #f4f4f4;
  padding: 40px 70px 64px 70px;
}
.related-content {
  min-height: 300px;
  width: 385px;
  cursor: "pointer";
  flex-shrink: 0;
}
.related-card-title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
@media screen and (max-width: 600px) {
  .related-contents {
    padding: 0;
  }
  .related-content {
    width: 250px;
    margin-bottom: 20px;
    cursor: "pointer";
    flex-shrink: 0;
  }
  .related-img {
    height: 200px;
    object-fit: cover;
  }
  .related-card-title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
}
