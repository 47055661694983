.vote-active {
  border-color: #00d445;
}
button:focus {
  outline: 0;
}
.vote-fill {
  fill: #00d445;
}
.btn-vote {
  border: 1px solid #dee2e6 !important;
}
.comment-vote-count {
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.post-vote-count {
}
.button-vote {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 0.875rem;
  user-select: none;
  background-color: transparent;
}
.vote-button button {
  line-height: 0px;
}
