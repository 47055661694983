.main-div {
  margin-top: 150px;
  margin-bottom: 50px;
}
.content-card {
  width: 700px;
  height: 500px;
}
.content-card.card {
  border: 2px solid #c3c3c3 !important;
}
.face {
  height: 180px;
  width: 180px;
}
.face-div {
  background-color: white;
  border: 2px solid #c3c3c3;
  border-radius: 142px;
  padding: 40px 40px;
  top: -135px;
  left: 259px;
}
.msg {
  margin-top: 170px;
  font-size: 50px;
  padding: 0 30px;
}
.msg-contents {
  text-align: center;
  font-size: 30px;
  padding: 0 30px;
}
.modal-buttons {
}
.buy {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
}
.cancel {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}
