#root {
  background-color: #f4f4f4;
}
.container {
  max-width: 1320px;
}
.navbar {
  width: auto;
  background-color: #ffffff;
  color: #000;
  top: 0;
  z-index: 1000;
  padding: 0;
  max-height: 81px;
}
.navbar-toggler {
  border: none;
}
@media screen and (min-width: 601px) {
  .nav-user {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #aeaeae;
  }
}

@media screen and (max-width: 600px) {
  .nav-user {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
}

@media screen and (min-width: 601px) {
  .nav-points {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
  }
  .nav-icon {
    width: 40px;
    height: 48px;
    object-fit: contain;
  }
}

@media screen and (max-width: 600px) {
  .nav-points {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .nav-icon {
    width: 27px;
    height: 32px;
    object-fit: contain;
  }
}

@media screen and (min-width: 601px) {
  .nav-profile-user {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 30px;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .nav-profile-user {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;
  }
}

.bell-icon {
  position: relative;
  width: 21px;
  height: 24px;
  cursor: pointer;
}
.dot {
  position: absolute;
  top: 10px;
  right: 12px;
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 5px;
}

/* notification */
@media screen and (min-width: 991px) {
  .notification-dropdown {
    width: 300px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #fefefe;
    background-color: #ffffff;
    margin-right: 130px;
    top: 66px;
    padding: 0;
  }
  .notification-dropdown:before {
    content: "";
    position: absolute;
    top: -13px;
    right: 22px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.15);
  }

  .notification-dropdown:after {
    content: "";
    position: absolute;
    top: -13px;
    right: 22px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ffffff;
  }
  .top-bar {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    padding: 8px 0px 8px 0px;
  }
}

@media screen and (max-width: 990px) {
  .notification-dropdown {
    width: 300px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #fefefe;
    background-color: #ffffff;
    top: 63px;
    padding: 0;
  }

  .notification-dropdown:before {
    content: "";
    position: absolute;
    top: -13px;
    right: 6px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.15);
  }

  .notification-dropdown:after {
    content: "";
    position: absolute;
    top: -13px;
    right: 6px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ffffff;
  }
  .top-bar {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    padding: 8px 15px 8px 15px;
  }
}
@media screen and (max-width: 600px) {
  .notification-dropdown {
    width: 100%;
    top: 55px;
  }
  .top-bar {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    padding: 8px 18px 8px 13px;
  }
  .notification-dropdown:before {
    content: "";
    position: absolute;
    top: -13px;
    right: 24px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.15);
  }

  .notification-dropdown:after {
    content: "";
    position: absolute;
    top: -13px;
    right: 24px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ffffff;
  }
}
.home {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.photos {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.navbar-brand {
  margin-right: 0;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.navModal {
  background-color: #000000;
}

.btn-navbar.nav-modal-text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.bottom-bar {
  padding-top: 15px;
}
.all {
  background-color: black;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.38px;
  padding-bottom: 15px;
  padding-right: 20px;
  padding-left: 20px;
  color: #c9c9c9;
}
ul.bottom-bar {
  margin-top: none !important;
  margin-bottom: none !important;
  margin-bottom: 0;
}
li.nav-props {
  padding-top: 40px;
  padding-right: 43px;
}

.navbar-default {
  transition: 500ms ease;
  background: transparent;
}
.navbar-default.scrolled {
  background: #000;
}
.btn-navbar {
  width: 62px;
  height: 23px;
  color: #000000;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.btn-navbar.active {
  font-weight: 900;
  background: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
@media screen and (min-width: 601px) {
  .btn-create {
    width: 108px;
    height: 48px;
    font-size: 18px;
    background-image: linear-gradient(114deg, #00d445, #6df499 114%);
    border-radius: 3px;
    color: #ffffff;
  }
  .publish {
    height: 48px;
    width: 108px;
  }
  .nav-items {
    width: 100%;
  }
  .tag {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 22px;
    padding-top: 12px;
    padding-right: 20px;
    padding-left: 20px;
    color: #c9c9c9;
    border-radius: 0px;
    cursor: pointer;
    background-color: black;
  }
  .tag:hover {
    color: #ffffff;
  }
  .tagbar {
    background-color: black;
  }
  .tag-contents::-webkit-scrollbar {
    display: none;
  }
  .tag-contents {
    scrollbar-width: none;
  }
  .tag.active {
    color: rgb(255, 255, 255);
    font-weight: bold;
    pointer-events: none;
  }
  .tag.active:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid #000000;
    content: " ";
    position: absolute;
    bottom: -12px;
    right: 50%;
    left: 50%;
  }
  .tag.btn {
    border: none;
    height: 52px;
  }
  .pipe {
    background-color: black;
    color: #535454;
    font-size: 35px;
  }
}

@media screen and (max-width: 600px) {
  .btn-create {
    width: 108px;
    height: 48px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    background-image: linear-gradient(114deg, #00d445, #6df499 114%);
    border-radius: 3px;
  }
  .publish {
    width: 79px;
    height: 32px;
  }
  .nav-items {
    width: inherit;
  }
  .tag {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 22px;
    padding-top: 12px;
    padding-right: 20px;
    padding-left: 20px;
    color: #c9c9c9;
    border-radius: 0px;
    cursor: pointer;
    background-color: black;
  }
  .tag:hover {
    color: #ffffff;
  }
  .tagbar {
    background-color: black;
  }
  .tag-contents::-webkit-scrollbar {
    display: none;
  }
  .tag-contents {
    scrollbar-width: none;
  }
  .tag.active {
    color: rgb(255, 255, 255);
    font-weight: bold;
    pointer-events: none;
  }
  .tag.active:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid #000000;
    content: " ";
    position: absolute;
    bottom: -12px;
    right: 50%;
    left: 50%;
  }
  .tag.btn {
    border: none;
    height: 40px;
  }
  .pipe {
    background-color: black;
    color: #535454;
    font-size: 27px;
  }
}
.btn-create:hover {
  color: #00d445;
  border-radius: 3px;
  background-image: linear-gradient(114deg, #ffffff, #ffffff 114%);
  border: 1px solid;
}
.btn-pill {
  width: 48px;
  height: 48px;
  border-radius: 30px !important;
}
.nav-profile {
  margin-left: 40px !important;
  margin-top: 28px !important;
}
.navbar-collapse {
  background: white;
}
.big-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}
.form-control {
  border: 0;
}
.card {
  border: none !important;
}
.formControl:focus {
  outline: 0;
}
