@media screen and (min-width: 601px) {
  .feature-card {
    min-height: 420px;
    width: 385px;
    cursor: pointer;
    flex-shrink: 0;
  }
  .feature-card-img {
    height: 300px;
    object-fit: cover;
  }
  .post-card-title {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .card-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  }
  .card-user-name {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .card-post-created {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .card-vote {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
  .card-vote-btn.active {
    border: solid 1px #00d445;
    color: #00d445;
  }
}

@media screen and (max-width: 600px) {
  .feature-card {
    min-height: 322px;
    width: 310px;
    cursor: pointer;
    flex-shrink: 0;
  }
  .feature-card-img {
    height: 200px;
    object-fit: cover;
  }
  .post-card-title {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .card-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  }
  .card-user-name {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .card-post-created {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .card-vote {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
  .card-vote-btn.active {
    border: solid 1px #00d445;
    color: #00d445;
  }
}
