.photo-grid {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
  cursor: pointer;
}
.scrolls {
  scroll-behavior: smooth;
}
.active-null {
  pointer-events: none;
}
@media screen and (min-width: 601px) {
  .tag-bar {
    top: 72px;
    z-index: 100;
  }
}

@media screen and (max-width: 600px) {
  .tag-bar {
    top: 57px;
    z-index: 100;
  }
}

