@media screen and (min-width: 601px) {
  .modal-xl {
    max-width: 80% !important;
  }
  .photo-comments {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .comment-section {
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    height: 85%;
    width: 100%;
  }
  .photo-comments::-webkit-scrollbar {
    display: none;
  }
  .photo-user {
    width: 24px;
    height: 24px;
    border-radius: 20px;
    object-fit: cover;
  }
  .user-profile {
    width: 32px;
    height: 32px;
    margin-right: 9px;
    border-radius: 20px;
    object-fit: cover;
  }
  .photo-content {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .user-name {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .user-time {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }

  .modal-content {
    background: none;
    border: none;
  }
  .navigation {
    cursor: pointer;
    color: rgb(255, 255, 255);
  }
  .modal-close :hover {
    cursor: pointer;
    font-weight: bold;
  }
  .vote-active {
    transition: 1s ease-in-out;
    border-color: #00d445;
  }
  .btn-vote-photo:hover {
    background-color: #e3e3e3;
  }
  .vote-fill {
    fill: #00d445;
  }
  .btn-vote {
    border: 1px solid #dee2e6 !important;
  }
  .photo-vote-count {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .photo-vote-sign {
    font-size: 12px;
    height: 14px;
    min-width: 11px;
  }
}

@media screen and (max-width: 990px) {
  .comment-section {
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    height: 85%;
    width: 100%;
  }
  .vote-section {
    width: 40%;
  }
  .modal-xl {
    max-width: 100% !important;
  }
  .row.pic-modal {
    margin: 0 !important;
  }
  .modal-dialog {
    margin: 0 !important;
  }
  .photo-content {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .user-name {
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .user-time {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f4f4f4;
  }
  .content-details {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .detail-hover {
    background-color: #00000094;
    left: 0;
    position: absolute;
    width: 100%;
    bottom: 0px;
    transition: 1s ease-in-out;
  }
}
@media screen and (max-width: 600px) {
  .vote-section {
    width: 40%;
  }
  .modal-xl {
    max-width: 100% !important;
  }
  .row.pic-modal {
    margin: 0 !important;
  }
  .modal-dialog {
    margin: 0 !important;
  }
  .photo-comments {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 75vh;
  }
  .photo-comments::-webkit-scrollbar {
    display: none;
  }
  .photo-user {
    width: 24px;
    height: 24px;
    border-radius: 20px;
    object-fit: cover;
  }
  .user-profile {
    width: 32px;
    height: 32px;
    margin-right: 9px;
    border-radius: 20px;
    object-fit: cover;
  }
  .photo-content {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .user-name {
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .user-time {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f4f4f4;
  }

  .modal-content {
    background: none;
    border: none;
  }
  .navigation {
    cursor: pointer;
    color: rgb(255, 255, 255);
  }
  .modal-close :hover {
    cursor: pointer;
    font-weight: bold;
  }
  .vote-active {
    transition: 1s ease-in-out;
    border-color: #00d445;
  }
  .btn-vote-photo:hover {
    background-color: #e3e3e3;
  }
  .vote-fill {
    fill: #00d445;
  }
  .btn-vote {
    border: 1px solid #dee2e6 !important;
  }
  .photo-vote-count {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .photo-vote-sign {
    font-size: 12px;
    height: 14px;
    min-width: 11px;
  }
  .content-details {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .detail-hover {
    background-color: #00000094;
    left: 0;
    position: absolute;
    width: 100%;
    bottom: 0px;
    transition: 1s ease-in-out;
  }
}
