@media screen and (min-width: 601px) {
  /* common */
  .feeds.container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .featured-cards {
    overflow-x: scroll;
    max-width: 1400px;
  }
  .featured-cards::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 990px) {
  .featured-cards.container {
    padding-left: 0 !important;
    padding-right: 0px !important;
  }
}
/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .feeds.container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .featured-cards {
    overflow-x: scroll;
    max-width: 1400px;
  }
  .featured-cards.container {
    padding-left: 0 !important;
    padding-right: 0px !important;
  }
  .featured-cards::-webkit-scrollbar {
    display: none;
  }
}
