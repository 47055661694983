.hover-img {
  height: 32px;
  width: 32px;
  border-radius: 20px;
  object-fit: cover;
}
.profile-text {
  font-size: 12px;
}
.count-img1 {
  height: 15px;
  width: 15px;
}
.count-img2 {
  height: 11px;
  width: 9px;
}
.count-text1 {
  font-size: 14px;
}
.count-text2 {
  font-size: 10px;
}
.bottom-left {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.bottom-right {
  bottom: 15px;
  right: 10px;
}
.blur {
  filter: blur(10px);
  animation-delay: 0s;
  animation-name: blur;
  /* animation-direction: alternate; */
  animation-iteration-count: infinite;
}
@keyframes blur {
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0px);
  }
}

.photo-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none !important;
  box-shadow: 0 2px 4px 0 rgba(151, 151, 151, 0.11);
}
.card-div {
  margin: 15px;
}
.card-divs {
  margin: 15px;
  position: absolute;
  bottom: 0;
}
.overlay {
  background-color: black;
  overflow: hidden;
  filter: blur(22px);
  position: absolute;
  height: 32px;
  left: 0;
  right: 0;
  bottom: 0;
}
.gallery-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, transparent 85%, black);
  pointer-events: none;
}

.center {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.btn-photo1 {
  background-color: #ffffff5c;
  border-radius: 2px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-photo2 {
  background-color: #ffffff;
  border-radius: 3px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  color: black;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-photo2.active{
  border: solid 2px #00d445;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00d445;
}
.btn-photo2:hover {
}
