@media screen and (min-width: 601px) {
  .option.active {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    border-bottom: 4px solid #00d445;
  }
  .discussions {
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .option {
    cursor: pointer;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7c7c7c;
  }
  .btn-more {
    font-size: 18px;
    color: #000000;
    border-color: #000000;
    min-height: 48px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn-more:disabled {
    color: #00000057;
    border-color: #00000040;
  }

  .post {
    border-radius: 3px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
  }
  .btn-vote-post {
    display: inline-block;
    height: 56px;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding-top: 5px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    min-width: 54px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn-vote-post.active {
    border: solid 1px #00d445;
  }
  .vote-icon {
    width: 22px;
    height: 20px;
    padding-bottom: 2px;
  }
  .vote-count {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
  .post-title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-bottom: 2px;
    color: #000000;
  }
  .post-desc {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b2b2b2;
  }
  .post-created {
    width: 24px;
    height: 24px;
    border-radius: 25px;
    object-fit: cover;
    margin-left: 6px;
  }
  .post-comment {
    width: 14px;
    height: 14px;
  }
  .comment-count {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
  .btn-post-tag {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
  .post-profile {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .post-time {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
}
@media screen and (max-width: 990px) {
  .discuss-nav {
    padding-top: 12px;
  }
  .option.active {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    border-bottom: 4px solid #00d445;
  }
  .discussions {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .option {
    font-size: 14px;
    padding-bottom: 12px;
    margin-right: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7c7c7c;
  }
  .btn-more {
    font-size: 18px;
    color: #000000;
    border-color: #000000;
    min-height: 48px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn-more:disabled {
    color: #00000057;
    border-color: #00000040;
  }

  .post {
    border-radius: 3px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
  }
  .btn-vote-post {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    min-width: 40px;
    height: 42px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn-vote-post.active {
    border: solid 1px #00d445;
  }
  .vote-icon {
    width: 11px;
    height: 14px;
    padding-bottom: 2px;
  }
  .vote-count {
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .post-title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .post-desc {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #b2b2b2;
  }
  .post-created {
    width: 24px;
    height: 24px;
    border-radius: 25px;
    object-fit: cover;
    margin-left: 6px;
  }
  .post-comment {
    width: 12px;
    height: 12px;
  }
  .discuss-comment {
    min-height: 15px;
    border-radius: 0.25rem;
  }
  .comment-count {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
  .btn-post-tag {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
  .post-profile {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .post-time {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
}

@media screen and (max-width: 600px) {
  .discuss-nav {
    padding-top: 12px;
  }
  .option.active {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    border-bottom: 4px solid #00d445;
  }
  .discussions {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .option {
    font-size: 14px;
    padding-bottom: 12px;
    margin-right: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7c7c7c;
  }
  .btn-more {
    font-size: 18px;
    color: #000000;
    border-color: #000000;
    min-height: 48px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn-more:disabled {
    color: #00000057;
    border-color: #00000040;
  }

  .post {
    border-radius: 3px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
  }
  .btn-vote-post {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    min-width: 40px;
    height: 42px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn-vote-post.active {
    border: solid 1px #00d445;
  }
  .vote-icon {
    width: 11px;
    height: 14px;
    padding-bottom: 2px;
  }
  .vote-count {
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .post-title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .post-desc {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #b2b2b2;
  }
  .post-created {
    width: 24px;
    height: 24px;
    border-radius: 25px;
    object-fit: cover;
    margin-left: 6px;
  }
  .post-comment {
    width: 12px;
    height: 12px;
  }
  .discuss-comment {
    min-height: 15px;
    border-radius: 0.25rem;
  }
  .comment-count {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
  .btn-post-tag {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
  .post-profile {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }
  .post-time {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #636363;
  }
}
